import React from 'react';
//image
import Image from 'next/image';
import Logo from '../../../../../public/images/Logowhite.png';
import appstore from '../../../../../public/landingimages/App store.svg';
import gpay from '../../../../../public/landingimages/gpay.svg';
//styles
import styles from './footer_desktop.module.scss';
import classNames from 'classnames';
import Link from 'next/link';

const Footer_desktop = () => {

    return (
        <div>
            <div className={styles.footer_desktop_background}>
                <div className={styles.row}>
                    <div className={classNames(styles.col_1)}>
                    </div>
                    <div className={classNames(styles.col_10)}>
                        <div className={styles.row}>
                            <div className={classNames(styles.col_6)}>
                                <div className={styles.footer_desktop_left}>
                                    <Link href='/' >
                                        <Image className={styles.footer_desktop_turf_logo} src={Logo} alt='logo' /> </Link>
                                    <div className={styles.footer_desktop_app_icons}>
                                        <Link href={'https://play.google.com/store/apps/details?id=com.turftown'} target='_blank'>
                                            <Image src={appstore} className={styles.desktop_apple_icon} alt='logo' loading='lazy' />
                                        </Link>
                                        <Link href={'https://apps.apple.com/in/app/turf-town/id1490231308'} target='_blank'>
                                            <Image className={styles.desktop_gpay_icon} src={gpay} alt='logo' loading='lazy' />
                                        </Link>
                                    </div>
                                    <p className={styles.iconic_text} style={{ fontFamily: "Nexa-Regular" }}>Designed for sport & made in Madras</p>
                                </div>
                            </div>
                            <div className={classNames(styles.col_6)}>
                                <div className={styles.row}>
                                    <div className={classNames(styles.col_6)}>
                                        <div className={classNames(styles.footer_company_desktop)}>
                                            <div className={classNames(styles.company_desktop_title)}>Company</div>
                                            <div className={styles.footer_desktop_content} ><Link href={'/contact'} className={styles['link_text']}>Contact Us</Link></div>
                                            <div className={styles.footer_desktop_content} ><Link target='_blank' href={"https://turftown.in/blog"} className={styles['link_text']}>Blog</Link></div>
                                            <div className={styles.footer_desktop_content} ><Link href={'/terms-of-service'} className={styles['link_text']}>Terms of Service</Link></div>
                                            <div className={styles.footer_desktop_content} ><Link href={'/privacy-policy'} className={styles['link_text']}>Privacy Policy</Link></div>
                                        </div>
                                    </div>
                                    <div className={classNames(styles.col_6)}>
                                        <div className={classNames(styles.footer_socal_desktop)}>
                                            <div className={classNames(styles.company_desktop_title)}>Social</div>
                                            <div className={styles.footer_desktop_content}
                                            ><Link href={'https://www.instagram.com/turftown.in/?hl=en'} target='_blank' className={styles['link_text']}>Instagram</Link></div>
                                            <div className={styles.footer_desktop_content}
                                            ><Link href={'https://twitter.com/Turf_Town'} target='_blank' className={styles['link_text']}>Twitter</Link></div>
                                            <div className={styles.footer_desktop_content}
                                            ><Link href={'https://www.linkedin.com/company/turf-town/'} target='_blank' className={styles['link_text']}>LinkedIn</Link></div>
                                            <div className={styles.footer_desktop_content}
                                            ><Link href={'https://www.facebook.com/turftown.in/'} target='_blank' className={styles['link_text']}>Facebook</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.col_1)}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer_desktop