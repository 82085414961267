import React from 'react';
//styles
import classNames from 'classnames';
import styles from './footer.module.scss';
//Images
import Image from 'next/image';
import Logowhite from '../../../../../public/images/Logowhite.png';
import apple from '../../../../../public/landingimages/newapple.webp';
import playstore from '../../../../../public/landingimages/mobilegoogleplay.webp';
import Link from 'next/link';

const Footer = () => {

    return (
        <>
            <div className={classNames(styles.footer_background)}>
                <div className={classNames(styles['mt-60'])}>
                    <Link href={'/'} >
                        <Image src={Logowhite} alt="" className={styles.Footer_turf_logo} />
                    </Link>
                </div>
                <div className={classNames(styles.footer_title, styles['mt-54'])}>
                    <div>
                        <div className={classNames(styles.title_footer)}>Company</div>
                        <div className={classNames(styles['mt-24'])} ><Link href={'/contact'} className={styles.footer_content}>Contact us</Link></div>
                        <div className={classNames(styles['mt-24'])} ><Link target='_blank' href={"https://turftown.in/blog"} className={styles.footer_content}>Blog</Link></div>
                        <div className={classNames(styles['mt-24'])} ><Link href={'/terms-of-service'} className={styles.footer_content}>Terms of Service</Link></div>
                        <div className={classNames(styles['mt-24'])} ><Link href={'/privacy-policy'} className={styles.footer_content}>Privacy Policy</Link></div>

                    </div>
                    <div>
                        <div className={classNames(styles.title_footer)}>Social</div>
                        <div className={classNames(styles['mt-24'])} ><Link href={'https://www.instagram.com/turftown.in/?hl=en'} target='_blank' className={styles.footer_content}>Instagram</Link></div>
                        <div className={classNames(styles['mt-24'])} ><Link href={'https://twitter.com/Turf_Town'} target='_blank' className={styles.footer_content}>Twitter</Link></div>
                        <div className={classNames(styles['mt-24'])} ><Link href={'https://www.linkedin.com/company/turf-town/'} target='_blank' className={styles.footer_content}>LinkedIn</Link></div>
                        <div className={classNames(styles['mt-24'])} ><Link href={'https://www.facebook.com/turftown.in/'} target='_blank' className={styles.footer_content}>Facebook</Link></div>
                    </div>
                </div>
                <div className={classNames(styles.playstores_icons, styles['mt-80'])}
                    style={{ display: "flex", gap: "15px" }}>
                    <Link href={'https://play.google.com/store/apps/details?id=com.turftown'} target='_blank'>
                        <Image src={apple} alt="apple-store-icon"
                            className={classNames(styles.Appstore_icon)} />
                    </Link>
                    <Link href={'https://apps.apple.com/in/app/turf-town/id1490231308'} target='_blank'>
                        <Image src={playstore} alt="android-store-icon"
                            className={classNames(styles.Playstore_icon)} />
                    </Link>
                </div>
                <div className={classNames(styles.terms_condition)}>Designed for sport & made in Madras</div>
            </div>
        </>
    )
}

export default Footer